'use client';

import { useEffect, useState, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { MapPin, Star, ArrowRight, CheckCircle, Clock } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { generateWorkerSlug } from '@/utils/slugs';

// Optimized skeleton with horizontal scroll
function WorkerSkeleton() {
  return (
    <div className="flex gap-4 px-4 overflow-x-auto pb-4 scrollbar-hide">
      {[1, 2, 3].map((i) => (
        <div key={i} className="bg-white rounded-2xl shadow-sm min-w-[280px]">
          {/* Header cu gradient și avatar */}
          <div className="relative h-16 bg-gradient-to-b from-gray-50 to-white">
            <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2">
              <div className="w-24 h-24 rounded-2xl border-4 border-white shadow-md overflow-hidden bg-gray-200 animate-pulse" />
            </div>
          </div>

          {/* Content */}
          <div className="pt-14 p-4">
            {/* Nume și Servicii */}
            <div className="space-y-2 mb-3">
              <div className="h-5 bg-gray-200 rounded-lg w-32 mx-auto animate-pulse" />
              <div className="h-4 bg-gray-200 rounded-lg w-24 mx-auto animate-pulse" />
            </div>

            {/* Stats Row */}
            <div className="flex items-center justify-center gap-3 mb-3">
              <div className="h-4 bg-gray-200 rounded-lg w-16 animate-pulse" />
              <span className="text-gray-300">•</span>
              <div className="h-4 bg-gray-200 rounded-lg w-20 animate-pulse" />
            </div>

            {/* Status Badge */}
            <div className="h-7 bg-gray-200 rounded-full w-28 mx-auto mb-3 animate-pulse" />

            {/* Button */}
            <div className="h-9 bg-gray-200 rounded-xl w-full animate-pulse" />
          </div>
        </div>
      ))}
    </div>
  );
}

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
};

const WorkerCard = ({ worker }) => (
  <div className="flex-shrink-0 w-[280px]">
    <Link 
      href={`/meseriasi/${generateWorkerSlug(worker.name, worker.mainTrade, worker.address, worker.id)}`}
      className="block bg-white rounded-2xl hover:shadow-lg transition-all duration-300
                 relative overflow-hidden group"
    >
      {/* Header cu Avatar și Nume */}
      <div className="relative h-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2">
          <div className="relative">
            <div className="w-24 h-24 rounded-2xl border-4 border-white shadow-md overflow-hidden">
              <Image
                src={worker.avatar_url || '/imageshttps://meseriaslocal.ro/logo.svg'}
                alt={worker.name || 'Default Avatar'}
                width={96}
                height={96}
                className="object-cover w-full h-full"
                loading="eager"
              />
            </div>
            {worker.is_verified && (
              <div className="absolute -bottom-1 -right-1 bg-white rounded-full p-0.5 shadow-md">
                <CheckCircle className="w-4 h-4 text-orange-500" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pt-14 p-4">
        {/* Nume și Servicii */}
        <div className="text-center mb-3">
          <h3 className="text-base font-bold text-gray-900 mb-0.5 group-hover:text-orange-500 transition-colors">
            {capitalizeFirstLetter(worker.name)}
          </h3>
          <p className="text-sm text-gray-600">{capitalizeFirstLetter(worker.mainTrade)}</p>
        </div>

        {/* Stats Row */}
        <div className="flex items-center justify-center gap-3 mb-3">
          <div className="flex items-center gap-1">
            <Star className="w-4 h-4 text-yellow-400 fill-yellow-400" />
            <span className="text-sm font-medium text-gray-700">
              {worker.averageRating.toFixed(1)} ({worker.reviewCount})
            </span>
          </div>
          <span className="text-gray-300">•</span>
          <div className="flex items-center gap-1">
            <MapPin className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-600 truncate max-w-[120px]">
              {capitalizeFirstLetter(worker.address)}
            </span>
          </div>
        </div>

        {/* Status Badge */}
        <div className="flex items-center justify-center gap-1.5 text-sm text-green-600 
                      bg-green-50 rounded-full py-1 px-3 mb-3">
          <Clock className="w-4 h-4" />
          <span>Disponibil</span>
        </div>

        {/* Button */}
        <button className="w-full py-2 bg-gray-900 text-white rounded-xl font-medium text-sm
                       group-hover:bg-orange-500 transition-colors flex items-center justify-center gap-2">
          Vezi profilul
          <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-0.5" />
        </button>
      </div>
    </Link>
  </div>
);

export default function FeaturedWorkers() {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchWorkers = useCallback(async () => {
    try {
      setLoading(true);
      const { data: workersData, error } = await supabase
        .from('profiles')
        .select(`
          id,
          name,
          address,
          phone,
          avatar_url,
          bio,
          is_verified,
          trade,
          created_at,
          reviews!reviews_worker_id_fkey (
            rating
          )
        `)
        .eq('role', 'worker')
        .ilike('avatar_url', '%supabase.co/storage/v1/object/public/profileImages%')
        .limit(15);

      if (error) throw error;

      const processedWorkers = workersData
        .filter(worker => worker.avatar_url?.includes('/profileImages/') && worker.name)
        .map(worker => {
          // Procesăm trade-ul în funcție de tip
          let services = [];
          if (worker.trade) {
            if (Array.isArray(worker.trade)) {
              services = worker.trade;
            } else if (typeof worker.trade === 'string') {
              // Verificăm dacă string-ul începe cu [ pentru a detecta array stringified
              if (worker.trade.startsWith('[')) {
                try {
                  services = JSON.parse(worker.trade);
                } catch (e) {
                  services = [worker.trade];
                }
              } else {
                services = [worker.trade];
              }
            }
          }

          return {
            ...worker,
            reviewCount: worker.reviews?.length || 0,
            averageRating: worker.reviews?.reduce((sum, review) => sum + (review.rating || 0), 0) / (worker.reviews?.length || 1) || 0,
            hasBio: !!worker.bio,
            mainTrade: services[0] || 'Meșter Universal',
            services: services
          };
        })
        .sort((a, b) => (
          (b.is_verified - a.is_verified) || 
          (b.hasBio - a.hasBio) || 
          (b.reviewCount - a.reviewCount) || 
          (b.averageRating - a.averageRating)
        ))
        .slice(0, 8);

      setWorkers(processedWorkers);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [fetchWorkers]);

  if (loading) return <WorkerSkeleton />;

  return (
    <section className="py-12 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto">
        <div className="text-center mb-8 px-4">
          <span className="text-orange-500 font-semibold text-sm uppercase tracking-wider mb-2 block">
            Profesioniști Verificați
          </span>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Găsește rapid un meseriaș de încredere pentru orice tip de lucrare
          </p>
        </div>

        {/* Scroll Container */}
        <div className="relative">
          <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none" />
          <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none" />
          
          <div className="overflow-x-auto scrollbar-hide">
            <div className="flex gap-4 px-4 pb-4">
              {workers.map((worker) => (
                <WorkerCard key={worker.id} worker={worker} />
              ))}
            </div>
          </div>
        </div>

        <div className="text-center mt-8 px-4">
          <Link
            href="/meseriasi"
            className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl
                     text-white bg-orange-500 hover:bg-orange-600 transition-colors gap-2"
          >
            Vezi toți meseriașii
            <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </section>
  );
}
