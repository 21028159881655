'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

// Static imports
import EntrepreneurFeatures from '@/components/services/meseriasi';
import RecentJobs from '@/components/home/RecentJobs';
import RecentQuestions from '@/components/questions/RecentQuestions';
import FeaturedWorkers from '@/components/home/FeaturedWorkers';

// Dynamic imports
const DynamicHero = dynamic(() => import('@/components/hero/NewHero'), {
  loading: () => <div className="h-[600px] bg-gray-900" />,
  ssr: false
});

const DynamicHowItWorks = dynamic(() => import('@/components/services/HowItWorks'));
const ServicesSlider = dynamic(() => import('@/components/services/slider'));
const RecentArticles = dynamic(() => import('@/components/blog/RecentArticles'));
const DynamicTestimonials = dynamic(() => import('@/components/testimonials/TestimonialsSection'));

export default function HomeClient() {
  return (
    <Suspense fallback={<div className="h-screen bg-gray-50" />}>
      <main>
        <DynamicHero />
        <FeaturedWorkers />
        <RecentJobs />
        <DynamicHowItWorks />
        <ServicesSlider />
        <EntrepreneurFeatures />
        <RecentArticles />
        <DynamicTestimonials />
        <RecentQuestions limit={3} />
      </main>
    </Suspense>
  );
}
