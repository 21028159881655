'use client';

import { useState, useEffect } from 'react';
import { useUser } from '@/hooks/useUser';
import { supabase } from '@/lib/supabase';
import Link from 'next/link';
import { MessageCircle, Clock, ChevronRight, Tool, HelpCircle } from 'lucide-react';

// Placeholder questions pentru SEO când nu avem întrebări reale
const PLACEHOLDER_QUESTIONS = [
  {
    id: 'placeholder-1',
    title: 'Cum repar o țeavă spartă în baie?',
    content: 'Am o țeavă spartă sub chiuvetă și apa se scurge constant. Care sunt pașii de bază pentru o reparație temporară până vine un instalator?',
    created_at: new Date().toISOString(),
    isPlaceholder: true,
    profiles: {
      name: 'Exemplu Client',
      trade: '["client"]',
      avatar_url: 'https://meseriaslocal.ro/logo.svg'
    }
  },
 
];

// Helper function pentru formatarea meseriilor
const formatTrades = (trades) => {
  if (!trades) return null;
  try {
    const tradesArray = typeof trades === 'string' ? JSON.parse(trades) : trades;
    return Array.isArray(tradesArray) ? tradesArray : null;
  } catch {
    return null;
  }
};

export default function RecentQuestions({ limit = 3 }) {
  const { user } = useUser();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAskForm, setShowAskForm] = useState(false);
  const [newQuestion, setNewQuestion] = useState({ 
    title: '', 
    content: '',
    contact: '' // Adăugăm câmp pentru contact opțional
  });

  const fetchQuestions = async () => {
    try {
      const { data: questions, error } = await supabase
        .from('questions')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(limit);

      if (error) throw error;

      if (!questions?.length) {
        setQuestions(PLACEHOLDER_QUESTIONS);
        setLoading(false);
        return;
      }

      const userIds = [...new Set(questions.map(q => q.user_id))];
      const { data: profiles } = await supabase
        .from('profiles')
        .select('*')
        .in('id', userIds);

      const questionsWithAuthors = questions.map(question => ({
        ...question,
        profiles: profiles?.find(profile => profile.id === question.user_id) || null
      }));

      setQuestions(questionsWithAuthors);
    } catch (error) {
      console.error('Error:', error);
      setQuestions(PLACEHOLDER_QUESTIONS);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    
    try {
      // Generăm un ID unic pentru utilizatorul anonim
      const anonymousId = `anon_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      
      const { error } = await supabase
        .from('questions')
        .insert([
          {
            title: newQuestion.title,
            content: `${newQuestion.content}\n\nContact: ${newQuestion.contact || 'Nespecificat'}`,
            user_id: user?.id || anonymousId,
            is_anonymous: !user?.id
          }
        ]);

      if (error) throw error;

      setNewQuestion({ title: '', content: '', contact: '' });
      setShowAskForm(false);
      fetchQuestions();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [limit]);

  if (loading) return null;

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">
            Întrebări & Răspunsuri
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Conectează-te cu meseriași profesioniști, găsește soluții pentru proiectele tale 
            sau împărtășește-ți experiența
          </p>
          <div className="mt-8 flex flex-col sm:flex-row items-center justify-center gap-4">
            <button
              onClick={() => setShowAskForm(true)}
              className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl
                       bg-orange-500 hover:bg-orange-600 transition-colors gap-2 text-white"
            >
              <HelpCircle className="w-5 h-5" />
              Pune o întrebare
            </button>
            <Link
              href="/intrebari"
              className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl
                       text-gray-700 bg-white border border-gray-200 hover:bg-gray-50 
                       transition-colors gap-2"
            >
              <MessageCircle className="w-5 h-5" />
              Vezi toate întrebările
            </Link>
          </div>
        </div>

        {showAskForm && (
          <div className="max-w-2xl mx-auto mb-12 bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <form onSubmit={handleSubmitQuestion} className="space-y-4">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                  Titlul întrebării
                </label>
                <input
                  id="title"
                  type="text"
                  placeholder="Ex: Cum repar o țeavă spartă?"
                  value={newQuestion.title}
                  onChange={(e) => setNewQuestion(prev => ({ ...prev, title: e.target.value }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 
                           focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
                  Detaliile întrebării
                </label>
                <textarea
                  id="content"
                  placeholder="Descrie problema ta în detaliu..."
                  value={newQuestion.content}
                  onChange={(e) => setNewQuestion(prev => ({ ...prev, content: e.target.value }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 
                           focus:ring-2 focus:ring-orange-500 focus:border-transparent h-32"
                  required
                />
              </div>
              <div>
                <label htmlFor="contact" className="block text-sm font-medium text-gray-700 mb-1">
                  Contact (opțional)
                </label>
                <input
                  id="contact"
                  type="text"
                  placeholder="Telefon sau email pentru a fi contactat (opțional)"
                  value={newQuestion.contact}
                  onChange={(e) => setNewQuestion(prev => ({ ...prev, contact: e.target.value }))}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 
                           focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Datele de contact sunt opționale și vor fi vizibile doar pentru meseriașii verificați
                </p>
              </div>
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowAskForm(false)}
                  className="px-4 py-2 text-gray-700 hover:text-gray-900"
                >
                  Anulează
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-orange-500 text-white rounded-lg 
                           hover:bg-orange-600 transition-colors"
                >
                  Postează întrebarea
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {questions.map(question => (
            question.isPlaceholder ? (
              // Placeholder Card (nu e link)
              <div 
                key={question.id}
                className="block bg-white rounded-xl shadow-sm border border-gray-200 
                         relative overflow-hidden"
              >
                {/* Badge pentru exemple */}
                <div className="absolute top-3 right-3">
                  <span className="inline-flex items-center px-2 py-1 rounded-md text-xs 
                                 font-medium bg-blue-50 text-blue-700">
                    Exemplu
                  </span>
                </div>

                <div className="p-6">
                  <div className="flex items-center gap-3 mb-3">
                    {/* Avatar */}
                    <div className="relative w-10 h-10 rounded-full overflow-hidden bg-gray-100 flex-shrink-0">
                      <img
                        src={question.profiles?.avatar_url}
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    
                    {/* Author Info */}
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-900">
                          {question.profiles?.name}
                        </span>
                      </div>
                      {question.profiles?.trade && (
                        <div className="flex flex-wrap gap-1 mt-1">
                          {formatTrades(question.profiles.trade)?.map((trade, index) => (
                            <span 
                              key={index}
                              className="inline-flex items-center px-2 py-0.5 rounded-full 
                                       bg-gray-100 text-gray-600 text-xs"
                            >
                              {trade}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    {question.title}
                  </h3>
                  <p className="text-gray-600 line-clamp-2 mb-4">
                    {question.content}
                  </p>

                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <span className="flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      {new Date(question.created_at).toLocaleDateString('ro-RO')}
                    </span>
                    <span className="flex items-center gap-1">
                      <MessageCircle className="w-4 h-4" />
                      0 răspunsuri
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              // Real Question Card (cu link)
              <Link 
                key={question.id} 
                href={`/intrebari/${question.id}`}
                className="block bg-white rounded-xl shadow-sm border border-gray-200 
                         hover:shadow-md transition-shadow group"
              >
                <div className="p-6">
                  <div className="flex items-center gap-3 mb-3">
                    {/* Avatar */}
                    <div className="relative w-10 h-10 rounded-full overflow-hidden bg-gray-100 flex-shrink-0">
                      <img
                        src={question.profiles?.avatar_url || '/https://meseriaslocal.ro/logo.svg'}
                        alt=""
                        className="object-cover w-full h-full"
                      />
                    </div>
                    
                    {/* Author Info */}
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-900">
                          {question.profiles?.name || 'Utilizator anonim'}
                        </span>
                        {question.profiles?.is_verified && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Verificat
                          </span>
                        )}
                      </div>
                      {question.profiles?.trade && (
                        <div className="flex flex-wrap gap-1 mt-1">
                          {formatTrades(question.profiles.trade)?.map((trade, index) => (
                            <span 
                              key={index}
                              className="inline-flex items-center px-2 py-0.5 rounded-full 
                                       bg-gray-100 text-gray-600 text-xs"
                            >
                              {trade}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <h3 className="text-lg font-semibold text-gray-900 group-hover:text-orange-500 
                               transition-colors line-clamp-2 mb-2">
                    {question.title}
                  </h3>
                  <p className="text-gray-600 line-clamp-2 mb-4">
                    {question.content}
                  </p>

                  <div className="flex items-center justify-between text-sm text-gray-500">
                    <span className="flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      {new Date(question.created_at).toLocaleDateString('ro-RO')}
                    </span>
                    <span className="flex items-center gap-1">
                      <MessageCircle className="w-4 h-4" />
                      {question.answers_count || 0} răspunsuri
                    </span>
                  </div>
                </div>
              </Link>
            )
          ))}
        </div>
      </div>
    </section>
  );
} 
