'use client'

import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { Star, ArrowRight, Clock, MapPin, Wallet, Briefcase } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import { supabase } from '@/lib/supabase'
import { useAuth } from '@/context/AuthContext'

const categories = [
  { id: 'all', name: 'Toate' },
  { id: 'instalator', name: 'Instalații', icon: '🔧' },
  { id: 'reparatii-intretinere', name: 'Reparații', icon: '🛠️' },
  { id: 'finisaje', name: 'Finisaje', icon: '🎨' },
  { id: 'tamplar', name: 'Tâmplărie', icon: '🪚' },
  { id: 'zugrav', name: 'Zugrăveli', icon: '🖌️' },
  { id: 'pavator', name: 'Pavaje', icon: '🛣️' },
  { id: 'service-termopane', name: 'Service Termopane', icon: '🛠️' },
  { id: 'mentenanta-cladiri', name: 'Mentenanță Clădiri', icon: '🏢' }
]

const tradeIcons = {
  'zugrav': '🎨',
  'faiantar': '🛠️',
  'parchetar': '🪚',
  'tamplar': '🔨',
  'rigipsar': '🏗️',
  'instalator': '🔧',
  'electrician': '⚡',
  'instalator-centrale': '🌡️',
  'frigotehnist': '❄️',
  'constructor': '🏠',
  'zidar': '🧱',
  'dulgher': '🪜',
  'fierar-betonist': '🏗️',
  'demolator': '💪',
  'peisagist': '🌳',
  'pavator': '🛣️',
  'arhitect': '📐',
  'firma-curatenie': '🧹',
  'mentenanta-cladiri': '🏢',
  'service-termopane': '🛠️'
}

export default function RecentJobs() {
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [sortBy, setSortBy] = useState('recent')
  const [currentIndex, setCurrentIndex] = useState(0)
  const { user } = useAuth()

  // Verificăm dacă utilizatorul este meseriaș
  const isWorker = user?.user_metadata?.type === 'worker'

  useEffect(() => {
    fetchJobs()
  }, [selectedCategory])

  useEffect(() => {
    const timer = setInterval(() => {
      // Mută la următorul set de joburi la fiecare 5 secunde
      setCurrentIndex((prevIndex) => 
        prevIndex + 3 >= jobs.length ? 0 : prevIndex + 3
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [jobs.length]);

  async function fetchJobs() {
    try {
      setLoading(true)
      let query = supabase
        .from('jobs')
        .select('*')
        .eq('status', 'open')
        .order('created_at', { ascending: false })
        
      if (selectedCategory !== 'all') {
        query = query.eq('tradeType', selectedCategory)
      }

      const { data, error } = await query.limit(9)

      if (error) {
        console.error('Error fetching jobs:', error)
        return
      }

      let formattedJobs = data.map(job => {
        const isUnknownBudget = job.budget === "Nu știu încă" || job.budget === "Nespecificat"
        
        return {
          ...job,
          timeAgo: getTimeAgo(new Date(job.created_at)),
          budget: isUnknownBudget ? 0 : job.budget,
          budgetDisplay: isUnknownBudget ? "Nu știu încă." : `${job.budget}.`,
          location: job.address || 'Locație nespecificată'
        }
      })

      // Sortăm joburile doar dacă suntem meseriași și avem nevoie de sortare după buget
      if (isWorker) {
        switch (sortBy) {
          case 'budget-high':
            formattedJobs.sort((a, b) => b.budget - a.budget)
            break
          case 'budget-low':
            formattedJobs.sort((a, b) => a.budget - b.budget)
            break
          default:
            break
        }
      }

      setJobs(formattedJobs)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  // Helper function to format time ago
  function getTimeAgo(date) {
    const now = new Date()
    const diffInMinutes = Math.floor((now - date) / (1000 * 60))

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute`
    } else if (diffInMinutes < 1440) {
      const hours = Math.floor(diffInMinutes / 60)
      return `${hours} ore`
    } else {
      const days = Math.floor(diffInMinutes / 1440)
      return `${days} zile`
    }
  }

  const handleSort = (newSortBy) => {
    setSortBy(newSortBy)
    let sortedJobs = [...jobs]
    
    switch (newSortBy) {
      case 'budget-high':
        sortedJobs.sort((a, b) => b.budget - a.budget)
        break
      case 'budget-low':
        sortedJobs.sort((a, b) => a.budget - b.budget)
        break
      case 'recent':
        sortedJobs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        break
    }
    
    setJobs(sortedJobs)
  }

  // Modificăm Link-ul pentru a include verificarea tipului de utilizator
  const JobDetailsLink = ({ job }) => {
    if (!isWorker) {
      return (
        <Link
          href={`/dashboard/job-details/${job.id}`}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white 
            bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors group/link"
        >
          Vezi detalii
          <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover/link:translate-x-1" />
        </Link>
      )
    }

    return (
      <Link
        href={`/dashboard/job-details/${job.id}`}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white 
          bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors group/link"
        onClick={(e) => {
          // Prevenim navigarea dacă nu sunt suficiente credite
          if (!hasEnoughCredits) {
            e.preventDefault()
            // Aici poți adăuga o notificare sau un modal pentru credite insuficiente
          }
        }}
      >
        Vezi detalii
        <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover/link:translate-x-1" />
      </Link>
    )
  }

  if (loading) {
    return (
      <section className="py-24 bg-gradient-to-b from-slate-50 to-white">
        <div className="container mx-auto px-4">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <div key={i} className="animate-pulse">
                  <div className="bg-white rounded-2xl p-6 shadow-lg border border-slate-100">
                    <div className="h-6 bg-slate-200 rounded-lg w-3/4 mb-4" />
                    <div className="h-4 bg-slate-200 rounded-lg w-1/2 mb-6" />
                    <div className="space-y-3">
                      <div className="h-4 bg-slate-200 rounded-lg" />
                      <div className="h-4 bg-slate-200 rounded-lg w-5/6" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className=" bg-gradient-to-b from-white via-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-orange-500 font-semibold text-sm uppercase tracking-wider mb-4 block">
            Lucrări recente
          </span>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Descoperă cele mai recente oportunități de lucru din zona ta și conectează-te direct cu clienții
          </p>
        </div>

        {/* Jobs Scroll Container */}
        <div className="relative max-w-[1400px] mx-auto">
          <div className="overflow-x-auto  -mx-4 px-4 scrollbar-hide">
            <div className="flex space-x-6 w-max">
              {jobs.map((job) => (
                <Link
                  key={job.id}
                  href={`/dashboard/job-details/${job.id}`}
                  className="group bg-white rounded-2xl border border-gray-100
                           shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 
                           transform min-w-[300px] max-w-[300px] first:ml-4 last:mr-4 overflow-hidden
                           flex flex-col"
                >
                  {/* Header */}
                  <div className="p-4 border-b border-gray-100">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-between gap-2">
                        <span className="text-sm font-medium px-2.5 py-1 bg-orange-100 text-orange-600 rounded-full truncate">
                          {categories.find(c => c.id === job.tradeType)?.name || 'Diverse'}
                        </span>
                        {job.budget !== "Nu știu încă" && job.budget !== "Nespecificat" && (
                          <span className="flex-shrink-0 bg-green-50 text-green-600 text-sm font-medium px-2.5 py-1 rounded-full">
                            {job.budget} RON
                          </span>
                        )}
                      </div>
                      <h3 className="text-base font-bold text-gray-900 line-clamp-2">
                        {job.title}
                      </h3>
                      <div className="flex items-center gap-3 text-gray-500 text-sm">
                        <span className="flex items-center gap-1">
                          <Clock className="w-3.5 h-3.5" />
                          {job.timeAgo}
                        </span>
                        <span className="flex items-center gap-1">
                          <MapPin className="w-3.5 h-3.5" />
                          <span className="truncate">{job.location}</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Content */}
                  <div className="p-4 flex-1 flex flex-col">
                    <p className="text-gray-600 text-sm line-clamp-2 mb-3">
                      {job.description}
                    </p>

                    {job.categories?.length > 0 && (
                      <div className="flex flex-wrap gap-1.5 mb-4">
                        {job.categories.slice(0, 3).map((category, index) => (
                          <span
                            key={index}
                            className="bg-gray-50 text-gray-600 text-xs px-2 py-0.5 rounded-full"
                          >
                            {category}
                          </span>
                        ))}
                        {job.categories.length > 3 && (
                          <span className="text-xs text-gray-500">
                            +{job.categories.length - 3}
                          </span>
                        )}
                      </div>
                    )}

                    {/* Button */}
                    <div className="mt-auto">
                      <span className="inline-flex items-center justify-center w-full bg-orange-500 hover:bg-orange-600
                        text-white font-medium py-2 px-4 rounded-lg transition-all gap-2 group-hover:shadow text-sm">
                        Vezi detalii
                        <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="text-center mt-12">
        <Link
            href="/lucrari-actuale"
            className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl
                     text-white bg-black hover:bg-gray-800 transition-all duration-300
                     shadow-lg shadow-orange-500/20 hover:shadow-orange-500/30 gap-2"
          >
            Vezi lucrări
            <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </section>
  )
} 